.container {
  background-color: var(--blue-1);
  width: 100vw;
  height: 100vh;
  color: var(--white-100);
  display: flex;
  justify-content: center;
}

.signIn {
  display: flex;
  gap: 1.5rem;
  height: fit-content;
  width: 41.875rem;
  flex-direction: column;
}

.signIn p {
  font-size: 0.75rem;
  color: var(--white-100);
}

.signIn > * {
  max-width: 38.875rem;
}

.divider {
  width: 100%;
  height: 0.0625rem;
  background: var(--border-tertiary-negative);
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.or {
  color: var(--white-100);
  display: inline-flex;
  padding: 0rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-spacing-stack-sm, 0.5rem);
  margin-top: -1rem;
  z-index: 3;
  position: absolute;
  background-color: var(--blue-1);
}

.white {
  border-color: var(--white-100);
  color: var(--white-100);
}

.signInForm {
  gap: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.signInForm input {
  background-color: transparent;
  border: 1px solid var(--border-tertiary-negative);
  margin-top: 0.5rem;
  color: var(--text-primary-negative);
}

.signInForm button {
  height: 3rem;
  float: right;
  min-width: 7rem;
}

.otpInput {
  margin-top: 1rem;
  background: var(--blue-4);
  border-radius: var(--border-radius-xlg);
  border: none;
  color: var(--white-100);
  font-size: 1.5rem;
  font-weight: bold;
  height: 3.5rem;
  line-height: 3.5rem;
}

.submitForm {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1rem;
}

.resend {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1.5rem;
}

.resend button,
.resend button div {
  color: var(--white-100);
  padding: 0;
}

.loading {
  align-items: center;
  display: flex;
  height: 7.5rem;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  height: 4.5rem;
  background-color: var(--blue-1);
}

.top button {
  color: var(--yellow-1);
}

button.switchSignInMethod {
  color: var(--yellow-1);
  width: 100%;
  justify-content: flex-start;
  padding-left: 0;
}

.emailCaption p {
  display: inline;
}

.signInForm .contactUs {
  color: var(--text-error);
  height: 1.25rem;
  min-width: 0;
  text-decoration: underline;
}

.withContactUsLink {
  display: flex;
}

@media (min-width: 768px) {
  .signIn > * {
    margin: auto;
    min-width: 38.875rem;
  }

  .signIn {
    margin-top: 5.5rem;
  }
}

@media (max-width: 768px) {
  .signInForm div:nth-of-type(2) {
    flex-direction: column-reverse;
    align-items: center;
  }

  .container {
    padding: 0 1rem;
  }

  .signInForm button {
    width: 100%;
  }

  .top {
    padding: 1rem;
  }
}
