.button {
  align-items: center;
  justify-content: center;
  border-radius: var(--button-corner);
  /* box-shadow: var(--shadow); */
  cursor: pointer;
  display: flex;
  font-family: var(--font-primary);
  font-size: 0.825rem;
  height: 2.5rem;
  line-height: 1.5rem;
  padding: 0.75rem 1rem;
  position: relative;
  white-space: pre-line;
  gap: 0.5rem;
}

.button-small {
  height: 2rem;
  padding: 0.75rem;
}

.button-medium {
  padding: 0.75rem 1rem;
}

.button-large {
  height: 3.5rem;
  padding: 1.5rem;
}

.primary {
  color: var(--button-primary-text-label);
  background: var(--button-primary-surface);
}

.primary p {
  color: var(--button-primary-text-label);
}

.primary.error {
  color: var(--button-primary-error-text-label);
  background-color: transparent;
  background: var(--button-primary-error-surface);
}

.primary.error p {
  color: var(--button-primary-error-text-label);
}

.secondary {
  color: var(--button-secondary-text-label);
  background-color: transparent;
  border: 1px solid var(--button-secondary-border);
}

.secondary p {
  color: var(--button-secondary-text-label);
}

.secondary.error {
  color: var(--button-secondary-error-text-label);
  border-radius: var(--button-corner, 0.125rem);
  border: 1px solid var(--button-secondary-error-border);
}

.secondary.error p {
  color: var(--button-secondary-error-text-label);
}

.tertiary {
  color: var(--button-tertiary-text-label);
  background: transparent;
}

.tertiary p {
  color: var(--button-tertiary-text-label);
}

.tertiary.error {
  color: var(--button-tertiary-error-text-label);
}

.tertiary.error p {
  color: var(--button-tertiary-error-text-label);
}

.secondary:hover,
.tertiary:hover {
  background: var(--blue-70-a05);
}

.secondary.error:hover,
.tertiary.error:hover {
  background: var(--red-60-a05);
}

.secondary:active,
.tertiary:active {
  background: var(--blue-70-a15);
}

.secondary.error:active,
.tertiary.error:active {
  background: var(--red-60-a10);
}

/* TODO: REMOVE */
.link {
  border: 0;
  background-color: transparent;
  color: var(--blue-5);
}

.withChildren {
  display: flex;
  height: auto;
  padding: 0;
}

.button:disabled {
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.4;
}

.button.loading,
.button.loading p {
  /* Hide text when loading */
  color: transparent;
}

.button.loading::after {
  --bg-color: var(--blue-70-a15);
  --loader-color: var(--blue-70);

  animation: spin 1s linear infinite;
  content: '';
  height: 24px;
  width: 24px;
  position: absolute;
  left: calc(50% - 12px);
  right: calc(50% - 12px);
  border: 2px solid var(--bg-color);
  border-bottom-color: var(--loader-color);
  border-radius: 50%;
  display: inline-block;
  animation: spin 1s linear infinite;
}

.button.primary.loading::after {
  --bg-color: var(--white-100-a20);
  --loader-color: var(--white-100);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
