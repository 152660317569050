.otpGroup {
  display: flex;
  width: 100%;
  column-gap: 1rem;
}

.otpInput {
  background: var(--blue-4);
  border-radius: var(--border-radius-xlg);
  border: none;
  color: var(--white-100);
  font-size: 1.5rem;
  font-weight: bold;
  height: 4.5rem;
  line-height: 3.5rem;
  text-align: center;
  width: 4.5rem;
  padding: 1.5rem;
  align-items: center;
  display: flex;
  gap: 0.5rem;
}

.otpInput:focus-visible {
  border: none;
  outline: 1px solid var(--white-100);
}

.otpInput.light {
  border-radius: 0.25rem;
  border: 2px solid var(--border-primary);
  background: var(--white-100);
  color: var(--text-action);
}

.otpInput.light:focus-visible {
  border: 2px solid var(--border-action, #1f40ba);
}
