.content {
  width: inherit;
  padding: 1rem;
}

.content.noPadding {
  padding: 0;
}

/*
LARGE SCREEN MODIFICATIONS
*/
@media (min-width: 1024px) {
  .content {
    flex-grow: 1;
  }
}
